
/**
 * router/index.ts
 *
 * Automatic routes for `./src/pages/*.vue`
 */

// Composables
import { createRouter, createWebHistory } from 'vue-router/auto'
import { routes } from 'vue-router/auto-routes'
import { getUser } from '../oidc-client'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
});

// Global navigation guard to enforce authentication and store the original route
router.beforeEach(async (to, from, next) => {
  const user = await getUser();
  if (user) {
    next(); // User is authenticated, allow access
  } else {
    if (to.path === '/callback' || to.path === '/login' || to.path === '/renew') {
      next(); // Allow access to login, renew and callback routes
    } else {
      // Store the original route in a query parameter and redirect to login
      next({
        path: '/login',
        query: { returnUrl: to.fullPath }
      });
    }
  }
});
export default router
