import { ref } from 'vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import { TOOLBOX_ITEMS, TIMELINE_TASKS, DATE_FORMAT, BAR_CATEGORIES, DB_DATE_FORMAT, DATE_ONLY_FORMAT, PRELOAD_DAYS_BUFFER } from '@/utils/metadata';
import { uniformDate, getCurrentMovedBars, compareObjects } from '@/utils/utils';
import { selectedBar, selectedBars, selectBar } from '@/utils/updateModels';
import { getAllCatalogData, getAllTasks, getAllRows, setChangeListener, saveOrDelete, getUuids, getDocs, getAllTemplates, getAllCourseActions } from '@/utils/dataSource';
import { DoublyLinkedList, DoublyLinkedListNode } from '@datastructures-js/linked-list'
import { store } from '@/utils/store';
import { loadConfig } from "@/config";

// load external configuration parameters
const config = await loadConfig();
const MODEL_DEBOUNCE_TIMEOUT = 10 * config.data.AFTER_POLL_TIMEOUT;

/*-------------------- MODELS --------------------*/

const PREFIX_NEW = 'new-';

const timelineModel = ref([]);
const toolboxModel = ref([]);
const ganttPreviewModel = ref([]);

const catData = {
  order  : [],
  vehicle: [],
  driver : []
}
const otherCatData = {
  repair : []
}
const taskMap = new Map();
const templateMap = new Map();

// Helpers
let orders, drivers, vehicles, repairs, tasks;

let catIndex = 0;
const catTypes = new Map([
  [catIndex++, 'order'],
  [catIndex++, 'vehicle'],
  [catIndex++, 'driver']
]);
const catTypesArray = Array.from(catTypes.values());

const catItemMaps = {
  order: null,
  vehicle: null,
  driver: null
}

const otherCatTypes = new Map([
  [catIndex++, 'repair']
]);

const otherMaps = {
  trailerLoaded: null,
  repair: null,
}

const idVsModelTask = new Map();

function setCatItemMaps(timelineStart) {
  catItemMaps.vehicle = new Map();
  catItemMaps.driver = new Map();
  catItemMaps.order = new Map();
  otherMaps.repair = new Map();

  orders.forEach(elem => {
    elem.availSince = dayjs(elem.source_after, DB_DATE_FORMAT).format(DATE_FORMAT);
    catItemMaps.order.set("" + elem.Id, elem);
  });
  vehicles.forEach(elem => {
    elem.availSince = timelineStart;
    elem.availAddress = null;
    catItemMaps.vehicle.set("" + elem.Id, elem);
  });
  drivers.forEach(elem => {
    elem.availSince = timelineStart;
    catItemMaps.driver.set("" + elem.Id, elem);
  });

  repairs.forEach(elem => {
    elem.availSince = dayjs(elem.after, DATE_ONLY_FORMAT).format(DATE_FORMAT);
    otherMaps.repair.set("" + elem.Id, elem);
  });
}

function setTaskMaps() {
  idVsModelTask.clear();
  tasks.forEach(elem => {
    const isCatalogType = catTypesArray.some(type => type === elem.cat);
    let extended_name = '';
    if (!isCatalogType) {
      catTypes.forEach(type => {
        if (elem[type + '_id']) {
          if (extended_name) {
            extended_name += ', ';
          }
          extended_name += catItemMaps[type].get(elem[type + '_id'])?.name;
        }
      });
      otherCatTypes.forEach(type => {
        if (elem[type + '_id']) {
          if (extended_name) {
            extended_name += ', ';
          }
          extended_name += otherMaps[type].get(elem[type + '_id'])?.name;
        }
      });
    }
    const catItem = isCatalogType ? catItemMaps[elem.cat].get(elem[elem.cat + '_id']) : null;
    let name = catItem?.name || extended_name || '';
    if (elem.cat === 'order' && elem.type !== 'service') {
      name += ': '  + (catItem?.source_address || '?');
      name += ' - ' + (catItem?.target_address || '?');
    }
    else if (elem.cat === 'order' && elem.type === 'service') {
      name = 'Service Order';
    }
    else if (elem.cat === 'action') {
      name += (name ? ': ' : '') + elem.type;
    }
    const catType = catItem?.type || '';
    const task = {
      id        : elem._id,
      createdAt : elem.created_at,
      start     : elem.after,
      end       : elem.until,
      name,
      title     : name,
      catType,
      type      : elem.type,
      cat     :   elem.cat,
      vehicleId : elem.vehicle_id,
      driverId  : elem.driver_id,
      orderId   : elem.order_id,
      courseId  : elem.course_id,
      repairId  : elem.repair_id,
      rowId     : elem.row_id
    };
    if (elem.cat === 'action') {
      task.sourceAddress = elem.source_address || null;
      task.targetAddress = elem.target_address || null;
      task.sourceLocation = elem.source_location || null;
      task.targetLocation = elem.target_location || null;
      if (elem.templateName) {
        task.templateName = elem.templateName;
      }
    }
    idVsModelTask.set(elem._id, task);
  });
}

function preprocessCatItemsData(timelineStart) {
  catTypes.forEach(cat => {
    catItemMaps[cat].forEach(catItem => {
      if (cat === 'order') {
        catItem.loadScheduled = null;
        catItem.unloadScheduled = null;
      }
    });
  });
  otherCatTypes.forEach(cat => {
    otherMaps[cat].forEach(catItem => {
      if (cat === 'repair') {
        catItem.scheduled = null;
      }
    });
  });
  const orderIdVsLoadActions = new Map();
  idVsModelTask.forEach(task => {
    if (task.repairId) {
      const repairCatItem = otherMaps.repair.get(task.repairId);
      if (repairCatItem) {
        repairCatItem.scheduled = true;
      }
    }
    catTypes.forEach(cat => {
      const catItem = catItemMaps[cat].get(task[cat + 'Id']);
      if (catItem) {
        if (!catItem.availSince) {
          catItem.availSince = dayjs(timelineStart, DATE_FORMAT);
        }
        const taskEnd = dayjs(task.end, DB_DATE_FORMAT).format(DATE_FORMAT);
        if (catItem.availSince < taskEnd) {
          catItem.availSince = taskEnd;
        }
      }
    });
    if (task.cat === 'action' && task.vehicleId && task.orderId) {
      const vehicle = catItemMaps.vehicle.get(task.vehicleId);
      const order = catItemMaps.order.get(task.orderId);
      if (vehicle && order) {
        if (task.type === 'load') {
          order.loadScheduled = true;
          if (vehicle.type === 'trailer' || vehicle.type === 'semi-trailer') {
            let actions = orderIdVsLoadActions.get(order.Id);
            if (!actions) {
              actions = [];
              orderIdVsLoadActions.set(order.Id, actions);
            }
            if (!actions.find(action => action.id === task.id))
            {
              actions.push(task);
            }
          }
        }
        else
        if (task.type === 'unload') {
          order.unloadScheduled = true;
          if (vehicle.type === 'trailer' || vehicle.type === 'semi-trailer') {
            let actions = orderIdVsLoadActions.get(order.Id);
            if (!actions) {
              actions = [];
              orderIdVsLoadActions.set(order.Id, actions);
            }
            if (actions.find(action => action.type === 'load')) {
              orderIdVsLoadActions.delete(order.Id);
            }
            else
            if (!actions.find(action => action.id === task.id))
            {
              actions.push(task);
            }
          }
        }
      }
    }

  });
  otherMaps.trailerLoaded = new Map();
  orderIdVsLoadActions.forEach(actions => {
    actions.forEach(action => {
      if (action.type === 'load') {
        const vehicle = catItemMaps.vehicle.get(action.vehicleId);
        const order = catItemMaps.order.get(action.orderId);
        otherMaps.trailerLoaded.set(vehicle.Id, {
          ...vehicle,
          name: vehicle.name + ': ' + order.name
        });
      }
    });
  });
}

// Actions

const actionMaps = {
  action: new Map([
    ['load', {
      id:   'load',
      name: 'Load',
      cat:  'action',
      type: 'load'
    }],
    ['transport', {
      id:   'transport',
      name: 'Transport',
      cat:  'action',
      type: 'transport'
    }],
    ['unload', {
      id:   'unload',
      name: 'Unload',
      cat:  'action',
      type: 'unload'
    }],
    ['empty', {
      id:   'empty',
      name: 'Empty',
      cat:  'action',
      type: 'empty'
    }],
    ['parking', {
      id:   'parking',
      name: 'Parking',
      cat:  'action',
      type: 'parking'
    }],
    ['gas-station', {
      id:   'gas-station',
      name: 'Gas Station',
      cat:  'action',
      type: 'gas-station'
    }],
    ['border', {
      id:   'border',
      name: 'Border Point',
      cat: 'action',
      type: 'border'
    }],
    ['ferry', {
      id:   'ferry',
      name: 'Ferry',
      cat:  'action',
      type: 'ferry'
    }]
  ])
};

// Toolbox

function buildToolboxModel(viewportTimeStart, viewportTimeEnd) {
  const after = dayjs(viewportTimeStart, DATE_FORMAT).format(DB_DATE_FORMAT);
  const until = dayjs(viewportTimeEnd, DATE_FORMAT).format(DB_DATE_FORMAT);
  const serviceOrder = {
    id:    'service',
    name: 'Service Order',
    cat: 'order',
    type: 'service',
    source_after: after,
    source_until: after,
    target_after: until,
    target_until: until
  };

  const actions = {
    action: new Map(actionMaps.action.entries())
  };
  console.log('templateMap', templateMap);
  Array.from(templateMap.values())
  .sort((a, b) => a.name < b.name ? -1 : (a.name > b.name) ? 1 : 0)
  .forEach(template => {
    actions.action.set(template._id, {
      id: template._id,
      name: template.name,
      cat: 'action',
      type: 'template'
    });
  });
  const toolboxMaps = Object.assign({}, catItemMaps, otherMaps, actions);
  toolboxModel.value = Object.keys(toolboxMaps).map(key => {
    const item = toolboxMaps[key];
    let bars = [];
    const groupId = 'toolbox_group_' + key;
    let metaCategory = TOOLBOX_ITEMS.find(elem => elem.category === key) || {};
    if (item) {
      const items = Array.from(item.values());
      if (key === 'order') {
        items.unshift(serviceOrder);
      }
      bars = items.map(bar => {
        const name = key === 'repair' && bar.vehicle?.name && !bar.name.startsWith(bar.vehicle?.name) ? (bar.vehicle?.name + ' ') + bar.name : bar.name;
        const metaType = (bar.type ? TOOLBOX_ITEMS.find(elem => elem.type === bar.type && elem.category === key) : null) || metaCategory;
        let nbar = {
          ...bar,
          _id:   bar.id || bar.Id,
          title: name,
          oldCategory: key,
          category: metaType.lane || key,
          color: metaType.color,
          icon:  metaType.icon,
          oldType: bar.type || 'transport',
          type: (key === 'action' || key === 'order' && bar.type === 'service' ? bar.type : 'transport')
        }
        if (metaType.category === 'repair') {
          nbar.type = 'repair';
        }
        return nbar;
      });
    }
    return {
      id:       groupId,
      title:    key,
      category: key,
      color:    metaCategory.color,
      icon:     metaCategory.icon,
      bars
    }
  });
  console.log('toolboxModel', toolboxModel.value);
}

// Timeline

let courseIdVsTasks;

const vehicleTypeVsOrder = new Map();
vehicleTypeVsOrder.set('truck', 1);
vehicleTypeVsOrder.set('semi-truck', 2);
vehicleTypeVsOrder.set('trailer', 3);
vehicleTypeVsOrder.set('semi-trailer', 4);

function sortTasks(a, b) {
  if (a.courseIdInternal === b.courseIdInternal) {
    return 0;
  }
  const bundleA = courseIdVsTasks.get(a.courseIdInternal);
  const bundleB = courseIdVsTasks.get(b.courseIdInternal);
  if (bundleA.min < bundleB.min) {
    return -1;
  }
  else
  if (bundleA.min > bundleB.min) {
    return 1;
  }
  else
  if (bundleA.createdAt < bundleB.createdAt) {
    return -1;
  }
  else
  if (bundleA.createdAt > bundleB.createdAt) {
    return 1;
  }
  else {
    return 0;
  }
};

function sortTasks2(a, b) {
  if (a.cat < b.cat) {
    return -1;
  }
  else
  if (a.cat > b.cat) {
    return 1;
  }
  else
  if (a.cat === b.cat && b.cat === 'order' && a.orderId && b.orderId) {
    if (a.start < b.start) {
      return -1;
    }
    else if (a.start > b.start) {
      return 1;
    }
    else
    if (a.name < b.name) {
      return -1;
    }
    else
    if (a.name > b.name) {
      return 1;
    }
    else
    if (a.createdAt < b.createdAt) {
      return -1;
    }
    else
    if (a.createdAt > b.cleatedAt) {
      return 1;
    }
    else {
      return 0;
    }
  }
  else
  if (a.cat === b.cat && b.cat === 'vehicle' && a.vehicleId && b.vehicleId) {
    const av = catItemMaps.vehicle.get(a.vehicleId) || {};
    const bv = catItemMaps.vehicle.get(b.vehicleId) || {};
    const at = vehicleTypeVsOrder.get(av.type) || 0;
    const bt = vehicleTypeVsOrder.get(bv.type) || 0;
    if (at < bt) {
      return -1;
    }
    else
    if (at > bt) {
      return 1;
    }
    if (a.start < b.start) {
      return -1;
    }
    else if (a.start > b.start) {
      return 1;
    }
    else
    if (a.createdAt < b.createdAt) {
      return -1;
    }
    else
    if (a.createdAt > b.cleatedAt) {
      return 1;
    }
    else
    if (a.name < b.name) {
      return -1;
    }
    else
    if (a.name > b.name) {
      return 1;
    }
    else {
      return 0;
    }
  }
  else
  if (a.cat === b.cat && b.cat === 'driver' && a.driverId && b.driverId) {
    if (a.name < b.name) {
      return -1;
    }
    else
    if (a.name > b.name) {
      return 1;
    }
    else
    if (a.start < b.start) {
      return -1;
    }
    else
    if (a.start > b.start) {
      return 1;
    }
    else
    if (a.createdAt < b.createdAt) {
      return -1;
    }
    else
    if (a.createdAt > b.cleatedAt) {
      return 1;
    }
    else {
      return 0;
    }
  }
  else
  if (a.start < b.start) {
    return -1;
  }
  else
  if (a.start > b.start) {
    return 1;
  }
  else
  if (a.createdAt < b.createdAt) {
    return -1;
  }
  else
  if (a.createdAt > b.cleatedAt) {
    return 1;
  }
  else
  if (a.name < b.name) {
    return -1;
  }
  else
  if (a.name > b.name) {
    return 1;
  }
  else {
    return 0;
  }
};

const laneTypes = ['order' , 'vehicle', 'driver', 'action'];

function addRoad(road, tmodel, rowId, viewportTimeStart, viewportTimeEnd) {
  const vstart = dayjs(viewportTimeStart, DATE_FORMAT);
  const vend = dayjs(viewportTimeEnd, DATE_FORMAT);
  let lane = 1;
  laneTypes.forEach(type => {
    let typeLanes = road?.laneMatrix.get(type);
    let typeAdded = false;
    if (typeLanes) {
      typeLanes.forEach(laneArray => {
        let row = idVsRow.get(laneArray.rowId);
        let info = idVsRowInfo.get(laneArray.rowId);
        const viewportTasks = laneArray.filter(task => dayjs(task.start, DB_DATE_FORMAT).isBefore(vend) && dayjs(task.end, DB_DATE_FORMAT).isAfter(vstart));
        if (!store.value.prefs.collapseEmptyRows || viewportTasks.length > 0 || typeLanes.size == 1 || typeLanes.size > 1 && !typeAdded && !info?.hidden || info?.shown) {
          typeAdded = true;
          const bars = [];
          tmodel.push({
            id: rowId++,
            type,
            lane,
            road: road?.road || 1,
            roadId: road?.roadId,
            rowId: laneArray.rowId,
            nextRowId: laneArray.nextRowId,
            prevRowId: laneArray.prevRowId,
            bars
          });
          const previewBars = [];
          previewBars.lane = lane;
          previewBars.road = road?.road || 1;
          if (!lensMode) {
            ganttPreviewModel.value.push(previewBars);
          }
          laneArray.forEach(tsk => {
            tsk.lane = lane;
            bars.push(tsk);
            if (!lensMode) {
              previewBars.push({
                id: tsk.id,
                start: new Date(tsk.start),
                end: new Date(tsk.end),
                title: tsk.title,
                dependencies: [],
                completed: 0,
                road: tsk.road,
                lane: tsk.lane
              });
            }
          });
          lane++;
        }
        else {
          if (row?._id) {
            if (!info) {
              info = {
                rowId: row._id,
                category: type
              };
              idVsRowInfo.set(row._id, info);
            }
            info.hidden = true;
          }
        }
      });
    }
    else {
      tmodel.push({
        id: rowId++,
        type,
        lane,
        road: road?.road || 1,
        rowId: PREFIX_NEW + crypto.randomUUID(),
        roadId: road?.roadId || PREFIX_NEW + crypto.randomUUID(),
        bars: []
      });
      const previewBars = [];
      previewBars.lane = lane;
      previewBars.road = road?.road || 1;
      if (!lensMode) {
        ganttPreviewModel.value.push(previewBars);
      }
      lane++;
    }
  });
  return rowId;
}

export function rebuildModel(timeStart, timeEnd) {
  prebuildTimelineModel(null, timeStart, timeEnd, true);
}

function isSameResource(task1, task2) {
  return task1.cat === task2.cat
    && (
      task1.cat === 'order'   && task1.orderId   === task2.orderId
      ||
      task1.cat === 'vehicle' && task1.vehicleId === task2.vehicleId
      ||
      task1.cat === 'driver'  && task1.driverId  === task2.driverId
    )
}

export function setTaskState(task, state) {
  if (!task || !state) {
    console.log('setTaskState: No task or state specified!');
    return;
  }
  if (!task.state) {
    task.state = [];
  }
  if (!task.state.find(elem => elem === state)) {
    task.state.push(state);
    console.log('setTaskState', state, task);
  }
}

function removeTaskState(task, state) {
  if (!task.state?.length) {
    return;
  }
  const index = task.state.findIndex(elem => elem === state);
  if (index > -1) {
    task.state.splice(index, 1);
  }
}

function setBundleState(bundle, state) {
  bundle.tasks.forEach(task => {
    setTaskState(task, state);
  });
}

function prebuildTimelineModel(tmodel, viewportTimeStart, viewportTimeEnd, rebuildModel) {
  const minDate = (dates) => dates.sort((a, b) => a < b ? -1 : (a == b ? 0 : 1))[0];
  const maxDate = (dates) => dates.sort((a, b) => a < b ? -1 : (a == b ? 0 : 1))[dates.length-1];

  const modelTasks = Array.from(idVsModelTask.values());

  courseIdVsTasks = new Map();

  const catVsResVsTasks = new Map();

  modelTasks.forEach(task => {
    let courseId = task.courseId;
    if (!courseId) {
      courseId = PREFIX_NEW + crypto.randomUUID();
    }
    task.courseIdInternal = courseId;
    let bundle = courseIdVsTasks.get(courseId);
    if (!bundle) {
      bundle = {
        courseId,
        taskIds: [],
        tasks: [],
        taskDates: [],
        taskCreatedAt: []
      };
      courseIdVsTasks.set(courseId, bundle);
    }
    bundle.taskIds.push(task.id);
    bundle.tasks.push(task);
    bundle.taskDates.push(task.start);
    bundle.taskDates.push(task.end);
    bundle.taskCreatedAt.push(task.createdAt);

    if (task.cat === 'order' || task.cat === 'vehicle' || task.cat === 'driver') {
      removeTaskState(task, 'OVERLAP');
      removeTaskState(task, 'OVERLAP_COURSE');
      removeTaskState(task, 'OVERLAP_SAME');

      let resVsTasks = catVsResVsTasks.get(task.cat);
      if (!resVsTasks) {
        resVsTasks = new Map();
        catVsResVsTasks.set(task.cat, resVsTasks);
      }
      let tsks = resVsTasks.get(task[task.cat + 'Id']);
      if (!tsks) {
        tsks = [];
        resVsTasks.set(task[task.cat + 'Id'], tsks);
      }
      tsks.push(task);
    }
  });

  courseIdVsTasks.forEach(bundle => {
    bundle.min = minDate(bundle.taskDates);
    bundle.max = maxDate(bundle.taskDates);
    bundle.createdAt = minDate(bundle.taskCreatedAt);
    delete bundle.taskDates;
    delete bundle.taskCreatedAt;

    const actions = bundle.tasks.filter(task => task.cat === 'action');
    if (actions.length) {
      actions.sort((a, b) => a.end < b.end ? -1 : (a.end > b.end ? 1 : 0));

      const vehicles = bundle.tasks.filter(task => task.cat === 'vehicle');
      if (vehicles.length) {
        vehicles
        .sort((a, b) => a.end < b.end ? -1 : (a.end > b.end ? 1 : 0))
        .forEach(task => {
          const catItem = catItemMaps.vehicle.get(task.vehicleId);
          if (catItem) {
            const action = actions.filter(elem => task.start < elem.end && task.end > elem.start).at(-1);
            catItem.availAddress = action?.targetAddress || action?.sourceAddress || null;
            catItem.availLocation = {
              lat: action?.targetLocation?.lat || action?.sourceLocation?.lat || null,
              lng: action?.targetLocation?.lng || action?.sourceLocation?.lng || null
            }
          }
        });
      }

      const drivers = bundle.tasks.filter(task => task.cat === 'driver');
      if (drivers.length) {
        drivers
        .sort((a, b) => a.end < b.end ? -1 : (a.end > b.end ? 1 : 0))
        .forEach(task => {
          const catItem = catItemMaps.driver.get(task.driverId);
          if (catItem) {
            const action = actions.filter(elem => task.start < elem.end && task.end > elem.start).at(-1);
            catItem.availAddress = action?.targetAddress || action?.sourceAddress || null;
            catItem.availLocation = {
              lat: action?.targetLocation?.lat || action?.sourceLocation?.lat || null,
              lng: action?.targetLocation?.lng || action?.sourceLocation?.lng || null
            }
          }
        });
      }
    }
  });

  catVsResVsTasks.forEach(resVsTasks => {
    resVsTasks.forEach(tsks => {
      tsks.forEach(task => {
        tsks.forEach(tsk => {
          if (tsk.id === task.id || task.cat === 'order' && task.type === 'service') {
            return;
          }
          if (task.start < tsk.end && task.end > tsk.start) {
            setTaskState(tsk, 'OVERLAP_SAME');
          }
        });
      });
    });
  });

  const idVsRoad = new Map();
  roadIds.forEach(roadId => {
    const roadArray = [];
    roadArray.roadId = roadId;
    roadArray.laneMatrix = new Map();
    rowsIndex.forEach(node => {
      const row = node.getValue();
      if (row.road === roadId) {
        const laneType = row.row_cat;
        let lanes = roadArray.laneMatrix.get(laneType);
        if (!lanes) {
          lanes = new Map();
          roadArray.laneMatrix.set(laneType, lanes);
        }
        const laneArray = [];
        laneArray.rowId = row._id;
        laneArray.nextRowId = row.next;
        laneArray.prevRowId = node.getPrev() != null ? node.getPrev().getValue()._id : null;
        lanes.set(row._id, laneArray);
      }
    });
    idVsRoad.set(roadId, roadArray);
  });
  const addedTaskIds = new Set();

  const sortedTasks = Array.from(idVsModelTask.values()).sort(sortTasks);

  const docIdsForRebuild = [];
  const rowIdVsNextRowId = new Map();

  sortedTasks.forEach((task) => {
    if (addedTaskIds.has(task.id)) {
      return;
    }
    const courseId = task.courseId;
    let bundle = courseIdVsTasks.get(courseId);
    if (!bundle) {
      task.course_id = null;
      bundle = {
        taskIds: [task.id],
        tasks: [task],
        min: task.start,
        max: task.end
      }
    }

    let added = false;
    let roadArray;
    if (task.rowId && !rebuildModel) {
      const row = idVsRow.get(task.rowId);
      if (row) {
        roadArray = idVsRoad.get(row.road);
        if (roadArray) {
          roadArray.filter(elem => bundle.min < elem.max && bundle.max > elem.min)
          .forEach((elem, index) => {
            setBundleState(elem, 'OVERLAP_COURSE');
            if (index === 0) {
              setBundleState(bundle, 'OVERLAP_COURSE');
            }
          });
          roadArray.push(bundle);
          added = true;
        }
      }
    }
    if (!added) {
      // search for existing road
      for (roadArray of idVsRoad.values()) {
        if (!roadArray.some(elem => bundle.min < elem.max && bundle.max > elem.min)) {
          roadArray.push(bundle);
            added = true;
            break;
        }
      }
    }
    if (!added) {
      // add a new road
      const roadId = PREFIX_NEW + crypto.randomUUID();
      roadArray = [bundle];
      roadArray.roadId = roadId;
      roadArray.laneMatrix = new Map();
      laneTypes.forEach(type => {
        let lanes = new Map();
        roadArray.laneMatrix.set(type, lanes);
        const laneArray = [];
        const rowId = PREFIX_NEW + crypto.randomUUID();
        laneArray.rowId = rowId;
        lanes.set(rowId, laneArray);
      });
      idVsRoad.set(roadId, roadArray);
    }
    bundle.taskIds.forEach(id => addedTaskIds.add(id));
  });

  let road = 1;

  idVsRoad.forEach(roadArray => {
    roadArray.road = road;
    roadArray.forEach(bundle => {
      bundle.tasks.sort(sortTasks2);
      bundle.tasks.forEach(tsk => {
        tsk.road = road;

        let laneType = tsk.cat;
        let lanes = roadArray.laneMatrix.get(laneType);
        let added = false;
        let laneArray;
        if (lanes && tsk.rowId && (!rebuildModel || laneType === 'action')) {
          laneArray = lanes.get(tsk.rowId);
          if (laneArray) {
            laneArray.filter(elem => tsk.start < elem.end && tsk.end > elem.start)
            .forEach((elem, index) => {
              setTaskState(elem, isSameResource(tsk, elem) ? 'OVERLAP_SAME' : 'OVERLAP');
              if (index === 0) {
                setTaskState(tsk, isSameResource(tsk, elem) ? 'OVERLAP_SAME' : 'OVERLAP');
              }
            });
            laneArray.push(tsk);
            added = true;
          }
        }
        if (!added) {
          if (!lanes) {
            lanes = new Map();
            roadArray.laneMatrix.set(laneType, lanes);
          }
          for (laneArray of lanes.values()) {
            if (!laneArray.some(elem => tsk.start < elem.end && tsk.end > elem.start)) {
              laneArray.push(tsk);
              if (tsk.rowId !== laneArray.rowId) {
                tsk.rowId = laneArray.rowId;
                docIdsForRebuild.push(tsk.id);
              }
              added = true;
              break;
            }
          }
        }
        if (!added) {
          laneArray = [tsk];
          const rowId = PREFIX_NEW + crypto.randomUUID();
          tsk.rowId = rowId;
          docIdsForRebuild.push(tsk.id);
          laneArray.rowId = rowId;
          lanes.set(rowId, laneArray);
        }
      });
    });
    road++;
  });

  if (rebuildModel) {
    console.log('rebuild');

    const placeholderUUIDs = [];
    const newRows = [];
    let prevLaneArray = null;

    // register all new ids and update next/prev ids
    idVsRoad.forEach(roadArray => {
      if (roadArray.roadId.startsWith(PREFIX_NEW)) {
        placeholderUUIDs.push(roadArray.roadId);
      }
      roadArray.laneMatrix.forEach(lanes => {
        lanes.forEach(laneArray => {
          if (laneArray.rowId.startsWith(PREFIX_NEW)) {
            placeholderUUIDs.push(laneArray.rowId);
          }
          if (prevLaneArray) {
            if (prevLaneArray.nextRowId !== laneArray.rowId) {
              docIdsForRebuild.push(prevLaneArray.rowId);
              rowIdVsNextRowId.set(prevLaneArray.rowId, laneArray.rowId);
            }
            prevLaneArray.nextRowId = laneArray.rowId;
            // laneArray.prevRowId = prevLaneArray.rowId;
          }
          prevLaneArray = laneArray;
        });
      });
    });
    console.log('idVsRoad', idVsRoad);
    console.log('docIdsForRebuild, rowIdVsNextRowId', docIdsForRebuild, rowIdVsNextRowId);
    // create new rows (if any)
    idVsRoad.forEach(roadArray => {
      roadArray.laneMatrix.forEach((lanes, category) => {
        lanes.forEach(laneArray => {
          if (laneArray.rowId.startsWith(PREFIX_NEW)) {
            newRows.push({
              _id: laneArray.rowId,
              cat: 'row',
              row_cat: category,
              road: roadArray.roadId,
              next: laneArray.nextRowId || null
            });
          }
        });
      });
    });
    console.log('placeholderUUIDs', placeholderUUIDs, 'newRows', newRows);

    // create new rows (if any) and update tasks
    if (docIdsForRebuild.length) {
      const placeholderVsRealUUID = new Map();
      saveRebuildChanges(docIdsForRebuild, placeholderUUIDs, newRows, rowIdVsNextRowId, placeholderVsRealUUID);
    }

    return;
  }

  if (!lensMode) {
    ganttPreviewModel.value = [];
  }

  let rowId = 1;

  idVsRoad.forEach(road => {
    rowId = addRoad(road, tmodel, rowId, viewportTimeStart, viewportTimeEnd);
  });
  console.log('idVsRoad, idVsRow', idVsRoad, idVsRow);
}

function saveRebuildChanges(docIdsForRebuild, placeholderUUIDs, newRows, rowIdVsNextRowId, placeholderVsRealUUID, retryCount) {
  if (retryCount && retryCount > 2) {
    console.log('saveRebuildChanges: Retry count exceeded -', retryCount);
    return;
  }
  getDocs(docIdsForRebuild)
    .then(async (idVsDoc) => {
      if (placeholderUUIDs?.length) {
        // generate UUIDs for rows and roads
        const uuids = await getUuids(placeholderUUIDs.length);
        placeholderUUIDs.forEach((value, index) => placeholderVsRealUUID.set(value, uuids[index]));
      }
      // replace placehoder Ids of tasks (row_id) and rows (_id, road, next) with generated above
      const docs = [];
      if (newRows) {
        newRows.forEach(row => {
          row._id = placeholderVsRealUUID.get(row._id);
          if (row.road && row.road.startsWith(PREFIX_NEW)) {
            row.road = placeholderVsRealUUID.get(row.road);
          }
          if (row.next && row.next.startsWith(PREFIX_NEW)) {
            row.next = placeholderVsRealUUID.get(row.next);
          }
          docs.push(row);
        });
      }
      idVsDoc.forEach(doc => {
        if (doc.cat === 'row') {
          let nextRowId = rowIdVsNextRowId.get(doc._id);
          if (nextRowId && nextRowId.startsWith(PREFIX_NEW)) {
            nextRowId = placeholderVsRealUUID.get(nextRowId);
          }
          doc.next = nextRowId || null;
        }
        else {
          const rowId = idVsModelTask.get(doc._id).rowId;
          if (rowId && rowId.startsWith(PREFIX_NEW)) {
            doc.row_id = placeholderVsRealUUID.get(rowId);
          }
          else {
            doc.row_id = rowId;
          }
        }
        docs.push(doc);
      });
      // apply changes: optional new rows/roads and tasks moved to other rows
      console.log('docs', docs);
      const result = await saveOrDelete(docs);
      console.log('rebuild result', result);

      const idsForRetry = [];
      result.forEach(doc => {
        if (doc.error === 'conflict') {
          idsForRetry.push(doc.id);
        }
      });
      if (idsForRetry.length) {
        if (!retryCount) {
          retryCount = 0;
        }
        retryCount++;
        console.log('saveRebuildChanges conflicts detected, retrying operation', idsForRetry, 'retryCount', retryCount);
        saveRebuildChanges(idsForRetry, null, null, rowIdVsNextRowId, placeholderVsRealUUID, retryCount);
      }
    });
}

const idVsBarModel = new Map();
const idVsRowModel = new Map();

function buildTimelineModel(timelineModelWork) {
  console.log('buildTimelineModel: timelineModelWork', timelineModelWork);
  idVsBarModel.clear();

  timelineModel.value = timelineModelWork.map(row => {
    const metaCategory = BAR_CATEGORIES.find(elem => elem.category === row.type) || {};
    let nrow = {
      id:       'timeline_row_' + row.rowId,
      index:    row.id,
      category: row.type,
      title: metaCategory.category,
      color: metaCategory.color,
      icon:  metaCategory.icon,
      //
      lane:  row.lane,
      road:  row.road,

      roadId:    row.roadId,
      rowId :    row.rowId,
      nextRowId: row.nextRowId,
      prevRowId: row.prevRowId,

      bars: row.bars.map(bar => {
        const metaType = TIMELINE_TASKS.find(elem => {
          if (bar.cat === 'vehicle' && bar.type === 'transport') {
            return elem.catType === bar.catType;
          }
          else {
            return elem.type === bar.type && elem.category === bar.cat;
          }
        }) || metaCategory;

        const isCompleted = bar.state?.includes('COMPLETED') || bar.completed === 100;

        let nbar = {
          ...bar,
          start: uniformDate( bar.start ),
          end:   uniformDate( bar.end ),
          ganttBarConfig: {
            id:          bar.id,
            immobile:    isCompleted,
            hasHandles: !isCompleted
          },
          category:    bar.cat,
          color:       metaType.color,
          icon:        metaType.icon
        };
        idVsBarModel.set(nbar.id, nbar);
        return nbar;
      })
    }
    idVsRowModel.set(nrow.rowId, nrow);
    return nrow;
  });
}

// Init
let timelineModelWorkOld = null;

function initModel(viewportTimeStart, viewportTimeEnd, data) {
  orders = data.orders;
  drivers = data.drivers;
  vehicles = data.vehicles;
  repairs = data.repairs;
  tasks = data.tasks;
  setCatItemMaps(viewportTimeStart);
  const actions = tasks.filter(task => task.cat === 'action' );
  actions.forEach(action => {
    const courseId = action.course_id;
    if (!courseId) {
      return;
    }
    const templateId = PREFIX_TEMPLATE + courseId;
    const template = templateMap.get(templateId);
    if (template) {
      action.templateName = template.name;
    }
    else {
      delete action.templateName;
    }
  });
  setTaskMaps();
  preprocessCatItemsData(viewportTimeStart);
  console.log('====initModel', viewportTimeStart, viewportTimeEnd, orders, drivers, vehicles, repairs, tasks, idVsModelTask);

  const timelineModelWork = [];
  prebuildTimelineModel(timelineModelWork, viewportTimeStart, viewportTimeEnd);

  buildToolboxModel(viewportTimeStart, viewportTimeEnd);

  if (!compareObjects(timelineModelWork, timelineModelWorkOld)) {
    buildTimelineModel(timelineModelWork);

    if (selectedBars.value?.length) {// Restore selectedBars[]
      selectedBars.value.forEach((ibar, idx) => {
        selectBar( idVsBarModel.get(ibar.id), (idx > 0), null );
      })
    }
    timelineModelWorkOld = [...timelineModelWork];
  }
}

let updateModelTimeout = null;

export function updateModel(viewportTimeStart, viewportTimeEnd, isBuildRowsIndex) {
  if (updateModelTimeout) {
    clearTimeout(updateModelTimeout);
  }

  updateModelTimeout = setTimeout(() => {
    if (isBuildRowsIndex) {
      buildRowsIndex();
    }
    const taskData = Array.from(taskMap.values());
    initModel(viewportTimeStart, viewportTimeEnd, {
      orders: catData.order, drivers: catData.driver, vehicles: catData.vehicle, repairs: otherCatData.repair, tasks: taskData
    });
  }, MODEL_DEBOUNCE_TIMEOUT);
}

// Load data

let changesSince = 'now';

const idVsRow = new Map();
const idVsRowInfo = new Map();
const rowsIndex = new DoublyLinkedList();
const roadIds = new Set();

function difference(set1, set2) {
  if (typeof set1.difference === 'function') {
    return set1.difference(set2);
  }
  const res = new Set(set1.values());
  for (let elem of set2.values()) {
    if (res.has(elem)) {
      res.delete(elem);
    }
  }
  return res;
}

function buildRowsIndex() {
  rowsIndex.clear();
  roadIds.clear();
  const nextIds = new Set();
  idVsRow.forEach(row => {
    if (row.next) {
      nextIds.add(row.next);
    }
  });
  const rowIds = new Set(idVsRow.keys());
  let headId = null;
  const heads = difference(rowIds, nextIds);
  if (heads.size >= 1) {
    if (heads.size > 1) {
      console.log('RowsIndex error: multiple heads found (heads, rowIds, nextIds): ', Array.from(heads.values()),  Array.from(rowIds.values()),  Array.from(nextIds.values()));
    }
    headId = heads.values().next().value;
  }
  else
  if (idVsRow.size > 0) {
    console.log('RowsIndex error: no head found');
  }
  if (headId) {
    // build index (linked list) starting from head
    for (let row = idVsRow.get(headId); row; row = idVsRow.get(row.next)) {
      const node = new DoublyLinkedListNode();
      node.setValue(row);
      rowsIndex.insertLast(node);
      roadIds.add(row.road);
    }
  }
  console.log('rowsIndex', rowsIndex.toArray());
}

function setTemplateMap(templates) {
  templates.forEach(template => {
    templateMap.set(template._id, template);
  });
}

function loadData(viewportTimeStart, viewportTimeEnd) {
  const bufferedTimelineStart = dayjs(viewportTimeStart, DATE_FORMAT).add(-PRELOAD_DAYS_BUFFER, 'day').format(DATE_FORMAT);
  const bufferedTimelineEnd = dayjs(viewportTimeEnd,   DATE_FORMAT).add( PRELOAD_DAYS_BUFFER, 'day').format(DATE_FORMAT);
  console.log('====loadData', bufferedTimelineStart, bufferedTimelineEnd);
  catData.order = [];
  catData.vehicle = [];
  catData.driver = [];
  otherCatData.repair = [];
  taskMap.clear();
  templateMap.clear();
  idVsRow.clear();
  rowsIndex.clear();
  roadIds.clear();

  //------------------- Load data

  const after = dayjs(bufferedTimelineStart, DATE_FORMAT).utc().format(DB_DATE_FORMAT);
  const until = dayjs(bufferedTimelineEnd, DATE_FORMAT).utc().format(DB_DATE_FORMAT);

  const catPromises = [];

  catTypes.forEach(type => {
    catPromises.push(getAllCatalogData(type));
  });

  otherCatTypes.forEach(type => {
    let query = null;
    if (type === 'repair') {
      query = '(until,ge,exactDate,'
      + dayjs(viewportTimeStart, DATE_FORMAT).format('YYYY-MM-DD')
      + ')~and(after,le,exactDate,'
      + dayjs(viewportTimeEnd, DATE_FORMAT).add(4, 'weeks').format('YYYY-MM-DD')
      + ')';
    }
    catPromises.push(getAllCatalogData(type, query));
  });

  Promise.all(catPromises)
  .then(results => {

    getAllRows()
    .then(rows => {
      rows.forEach(row => {
        if (row._deleted) {
          idVsRow.delete(row._id);
        }
        else {
          idVsRow.set(row._id, row);
        }
      });

      setChangeListener((changes, since) => {
        console.log('====changes, since', changes, since);
        changesSince = since;

        changes.forEach(change => {
          if (change._deleted) {
            if (idVsRow.has(change._id)) {
              idVsRow.delete(change._id);
            }
            else
            if (templateMap.has(change._id)) {
              templateMap.delete(change._id);
            }
            else
            if (taskMap.has(change._id)) {
              taskMap.delete(change._id);
            }
            else {
              console.log('Warning: deleting change not found', change);
            }
          }
          else
          if (change.cat === 'row') {
            idVsRow.set(change._id, change);
          }
          else
          if (change.cat === 'template') {
            templateMap.set(change._id, change);
          }
          else
          if (change.cat) {
            taskMap.set(change._id, change);
          }
          else {
            console.log('Error: Unsupported change', change);
          }
        });

        const bars = getCurrentMovedBars();
        if (bars?.length) {
          bars.forEach(bar => {
            const tsk = taskMap.get(bar.id);
            if (tsk) {//restore after/until of currently moved bars
              tsk.after = dayjs(bar.start, DATE_FORMAT).utc().format(DB_DATE_FORMAT);
              tsk.until = dayjs(bar.end  , DATE_FORMAT).utc().format(DB_DATE_FORMAT);
            }
          });
        }

        updateModel(viewportTimeStart, viewportTimeEnd, true);
      }, changesSince);

      results.forEach((res, idx) => {
        let type = catTypes.get(idx);
        if (type) {
          catData[type] = res;
        }
        else {
          type = otherCatTypes.get(idx);
          if (type) {
            otherCatData[type] = res;
          }
        }
      });

      const taskPromises = [];
      catTypes.forEach(type => {
        if (!catData[type] || catData[type].length == 0) {
          return;
        }

        catData[type].forEach(item => {
          taskPromises.push(getAllTasks(
            type + '_id',
            item.Id,
            type,
            after,
            until)
          );
        });
      });

      otherCatData.repair.forEach(item => {
        taskPromises.push(getAllTasks(
          'repair_id',
          item.Id,
          'action',
          after,
          until)
        );
      });

      taskPromises.push(getAllTasks(
        'type',
        'service',
        'order',
        after,
        until));

      Promise.all(taskPromises)
      .then(async taskResults => {
        const templates = await getAllTemplates();
        setTemplateMap(templates);

        const courseIds = new Set();
        const driverIdVsTasks = new Map();
        const courseIdVsActions = new Map();
        taskResults.forEach(taskRes => {
          if (taskRes.length && taskRes[0].cat === 'driver' && taskRes[0].driver_id) {
            driverIdVsTasks.set(taskRes[0].driver_id, taskRes);
          }
          taskRes.forEach(task => {
            taskMap.set(task._id, task);
            if (task.course_id && !task.repair_id) {
              courseIds.add(task.course_id);
            }
          });
        });
        if (courseIds.size) {
          const coursePromises = [];
          courseIds.forEach(courseId => {
            coursePromises.push(getAllCourseActions(courseId));
          });
          Promise.all(coursePromises)
          .then(actionResults => {
            actionResults.forEach(actionRes => {
              if (!actionRes.length) {
                return;
              }
              const courseId = actionRes[0].course_id;
              if (courseId) {
                courseIdVsActions.set(courseId, actionRes);
              }
              const templateId = PREFIX_TEMPLATE + courseId;
              actionRes.forEach(action => {
                taskMap.set(action._id, action);
                const template = templateMap.get(templateId);
                if (template) {
                  action.templateName = template.name;
                }
              });
            });
            // console.log('====driverIdVsTasks', driverIdVsTasks);
            // console.log('====courseIdVsActions', courseIdVsActions);
            //TODO schedule for each driver
            updateModel(viewportTimeStart, viewportTimeEnd, true);
          })
          .catch(actionError => {
            console.log('Action data error', actionError);
          });
        }
        else {
          updateModel(viewportTimeStart, viewportTimeEnd, true);
        }
      })
      .catch(taskError => {
        console.log('Task data error', taskError);
      });
    })
    .catch(error => {
      console.log('Catalog data error', error);
    });
  });
}

export function showNextHiddenRow(rowId, prevRowId, category, timelineStart, timelineEnd) {
  let rowInfo;
  if (rowId) {
    const row = idVsRow.get(rowId);
    if (row?.next) {
      rowInfo = idVsRowInfo.get(row.next);
    }
  }
  if (!rowInfo?.hidden && prevRowId) {
    rowInfo = idVsRowInfo.get(prevRowId);
  }
  if (rowInfo?.hidden && rowInfo.category === category) {
    delete rowInfo.hidden;
    rowInfo.shown = true;
    console.log('show hidden row', rowInfo.rowId);
    setTimeout(() => updateModel(timelineStart, timelineEnd), 10);
    return true;
  }
  console.log('no hidden row to show');
  return false;
}

export function prepareToShowRow(rowId, category) {
  if (!rowId) {
    return;
  }
  let rowInfo = idVsRowInfo.get(rowId);
  if (!rowInfo) {
    rowInfo = {
      rowId,
      category
    };
    idVsRowInfo.set(rowId, rowInfo);
  }
  delete rowInfo.hidden;
  rowInfo.shown = true;
  console.log('prepare to show row', rowId);
}

export function hideRow(rowId, category, timelineStart, timelineEnd) {
  if (!rowId) {
    return;
  }
  let rowInfo = idVsRowInfo.get(rowId);
  if (!rowInfo) {
    rowInfo = {
      rowId,
      category
    };
    idVsRowInfo.set(rowId, rowInfo);
  }
  delete rowInfo.shown;
  rowInfo.hidden = true;
  console.log('hide row', rowId);
  setTimeout(() => updateModel(timelineStart, timelineEnd), 10);
}

export function useModels(timelineStart, timelineEnd, lens/*, orders, drivers, vehicles, tasks*/) {
  lensMode = lens;

  loadData(timelineStart, timelineEnd);

  return {
    timelineModel,
    toolboxModel,
    ganttPreviewModel,
    showNextHiddenRow
  }
}

/*-------------------- Util element --------------------*/

export function useUtils() {
  return {
    catItemMaps,
    courseIdVsTasks,
    idVsBarModel,
    idVsRowModel,
    timelineModel
  }
}

let lensMode = false;

export function useLens({date, road}) {
  lensMode = true;
  const previewStart = dayjs(date).startOf('day');
  const previewEnd = previewStart.add(1, 'day');
  let found = false;
  timelineModel.value.forEach(row => {
    if (!found && road === row.road) {
      found = true;
      const el = document.getElementById(row.id);
      if (el) {
        el.scrollIntoView({behavior: "smooth", block: "center" });
      }
    }
  });

  return {
    previewStart: previewStart.format(DATE_FORMAT),
    previewEnd: previewEnd.format(DATE_FORMAT)
  }
}

export function resetLens() {
  lensMode = false;
}

function str(val) {
  return !val && val !== 0 ? null : '' + val;
}

export async function prepareTasksForUpdate(tasks, isDelete) {
  const docs = [];
  if (!tasks?.length) {
    console.log('No tasks specified');
    return docs;
  }
  const taskIds = [];
  tasks.forEach(task => {
    if (task.id) {
      taskIds.push(task.id);
    }
  });
  let idVsDoc;
  if (taskIds.length) {
    idVsDoc = await getDocs(taskIds);
  }
  let singleCount = 0;
  const groupVsUuid = new Map();
  tasks.forEach(task => {
    let doc = idVsDoc && task.id ? idVsDoc.get(task.id) : null;
    if (isDelete) {
      if (doc) {
        doc._deleted = true;
        task.ghost = true;
        docs.push(doc);
      }
      else {
        console.log('Task not found, skip delete', task);
      }
    }
    else {
      if (!doc) {
        console.log('Task not found, creating a new', task);
        doc = {
          created_at: dayjs().utc().format(DB_DATE_FORMAT),
          cat       : task.cat,
          type      : task.type,
          vehicle_id: str(task.vehicleId),
          driver_id : str(task.driverId),
          order_id  : str(task.orderId),
          repair_id : str(task.repairId)
        }
      }
      task.ghost = true;
      const after = dayjs(task.start, DATE_FORMAT).utc().format(DB_DATE_FORMAT);
      const until = dayjs(task.end, DATE_FORMAT).utc().format(DB_DATE_FORMAT);
      doc.after = after;
      doc.until = until;
      doc.row_id = task.rowId || null;
      if (task.cat === 'action') {
        doc.source_address = task.sourceAddress || null;
        doc.target_address = task.targetAddress || null;
        doc.source_location = task.sourceLocation || null;
        doc.target_location = task.targetLocation || null;
      }
      if (!task.courseId) {
        if (task.courseGroup) {
          groupVsUuid.set(task.courseGroup, null);
        }
        else {
          singleCount++;
        }
      }
      else 
      if (!doc.course_id) {
        doc.course_id = str(task.courseId);
      }
      docs.push(doc);
    }
  });
  const count = singleCount + groupVsUuid.size;
  if (count) {
    const uuids = await getUuids(count);
    if (groupVsUuid.size) {
      Array.from(groupVsUuid.keys()).forEach(key => {
        groupVsUuid.set(key, uuids.splice(0, 1)[0]);
      });
    }
    tasks.forEach((task, index) => {
      const doc = docs[index];
      if (!doc.course_id) {
        if (task.courseGroup) {
          doc.course_id = groupVsUuid.get(task.courseGroup);
        }
        else {
          doc.course_id =  uuids.splice(0, 1)[0];
        }
      }
    });
  }
  return docs;
}

export function upsertTask(task) {
  if (!task) {
    console.log('upsertTask: no task supplied');
    return;
  }

  updateOrDeleteTasks([task]);
}

const PREFIX_TEMPLATE = 'T-';
export function saveOrDeleteTemplate(courseId, name) {
  if (!courseId) {
    console.log('saveOrDeleteTemplate: no courseId supplied');
    return;
  }

  const templateId = PREFIX_TEMPLATE + courseId;
  getDocs([templateId])
  .then(async idVsDoc => {
    let doc;
    if (!idVsDoc.size) {
      if (!name) {
        console.log('saveOrDeleteTemplate: template not found, can not delete:', templateId);
        return;
      }
      console.log('saveOrDeleteTemplate: Create a new template', templateId, name);
      doc = {
        _id: templateId,
        cat: 'template',
        name
      }
    }
    else {
      doc = idVsDoc.get(templateId);
      if (!name) {
        console.log('saveOrDeleteTemplate: Delete a template', templateId);
        doc._deleted =  true;
      }
      else {
        console.log('saveOrDeleteTemplate: Modify a template', templateId, name);
        doc.name = name;
      }
    }
    await saveOrDelete([doc]);
  })
  .catch(err => {
    console.log('saveOrDeleteTemplate error', courseId, name);
  });
}

export async function loadTemplate(templateId, after, until) {
  if (!templateId || !templateId.startsWith(PREFIX_TEMPLATE)) {
    console.log('Missing or invalid templateId!');
    return;
  }
  const courseId = templateId.substring(PREFIX_TEMPLATE.length);
  const actions = await getAllCourseActions(courseId);
  let start = dayjs(after, DATE_FORMAT);
  const scale = 
    dayjs(until, DATE_FORMAT).diff(dayjs(after, DATE_FORMAT), 'minutes')
    / dayjs(actions.at(-1).until, DB_DATE_FORMAT).diff(dayjs(actions.at(0).after, DB_DATE_FORMAT), 'minutes');
  let prev;
  return actions.map(action => {
    if (prev) {
      start = start.add(dayjs(action.after, DB_DATE_FORMAT).diff(dayjs(prev.after, DB_DATE_FORMAT), 'minutes') * scale, 'minutes');
    }
    const end = start.add(dayjs(action.until, DB_DATE_FORMAT).diff(dayjs(action.after, DB_DATE_FORMAT), 'minutes') * scale, 'minutes');
    const act = {
      cat:  action.cat,
      type: action.type,
      start: start.format(DATE_FORMAT),
      end: end.format(DATE_FORMAT),
      sourceAddress: action.source_address || null,
      sourceLocation: {
        lat: action.source_location?.lat || null,
        lng: action.source_location?.lng || null
      },
      targetAddress: action.target_address || null,
      targetLocation: {
        lat: action.target_location?.lat || null,
        lng: action.target_location?.lng || null
      }
    };
    prev = action;
    return act;
  });
}

export function removeTask(task) {
  if (!task?.id) {
    console.log('removeTask: no task supplied or it has no id');
    return;
  }

  updateOrDeleteTasks([task], true);
}

export function updateOrDeleteTasks(tasks, isDelete, retryCount) {
  if (!tasks?.length) {
    console.log('updateTasks: no tasks supplied');
    return;
  }
  if (retryCount && retryCount > 2) {
    console.log('updateTasks: Retry count exceeded -', retryCount);
    return;
  }

  prepareTasksForUpdate(tasks, isDelete)
  .then(docs => {
    saveOrDelete(docs)
    .then(res => {
      console.log('saveOrDelete tasks ok', tasks, res);

      const idsForRetry = [];
      res.forEach((doc, index) => {
        if (doc.error) {
          const bar = tasks[index];
          if (bar) {
            bar.ghost = false;
          }
          if (doc.error === 'conflict') {
            idsForRetry.push(doc.id);
            setTaskState(bar, 'CONFLICT');
          }
          else
          if (doc.error === 'forbidden') {
            setTaskState(bar, 'FORBIDDEN');
          }
          else
          if (doc.error === 'unauthorized') {
            setTaskState(bar, 'UNAUTHORIZED');
          }
          else {
            setTaskState(bar, 'ERROR');
          }
        }
      });
      if (idsForRetry.length) {
        const idVsTask = new Map();
        tasks.forEach(task => { idVsTask.set(task.id, task) });
        const tasksForRetry = [];
        idsForRetry.forEach(id => { tasksForRetry.push(idVsTask.get(id)) });
        if (!retryCount) {
          retryCount = 0;
        }
        retryCount++;
        console.log('saveOrDelete conflicts detected, retrying operation', tasksForRetry, 'retryCount', retryCount);
        updateOrDeleteTasks(tasksForRetry, isDelete, retryCount);
      }
    })
    .catch(err => {
      console.log('saveOrDelete tasks error', err, tasks);
    });
  })
  .catch(err => {
    console.log('saveOrDelete tasks error2', err, tasks);
  });
}