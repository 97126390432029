import dayjs from 'dayjs';
import { computed, ref } from 'vue';
import { DATE_FORMAT, DB_DATE_FORMAT, TOOLBOX_ITEMS, BAR_CATEGORIES } from '@/utils/metadata';
import { upsertTask, removeTask, prepareTasksForUpdate, showNextHiddenRow, prepareToShowRow, hideRow, useUtils, saveOrDeleteTemplate, loadTemplate, setTaskState } from '@/utils/useModels';
import { getDoc, getDocs, saveOrDelete, rowCountByRoadAndRowCat, taskCountByRow, getUuids } from '@/utils/dataSource';
import useTimePositionMapping from '@/utils/useTimePositionMapping';
import { getLatLng } from '@/utils/utils';
import { store } from '@/utils/store';

// Bar actions

export function onBarAction(evt, bar, row) {
  // console.log('onBarAction', evt, bar, row);
  const actn = evt?.action;
  if (actn === 'delete') {
    deleteBar(bar, row);
    return;
  }

  if (actn === 'template-name-change') {
    bar.templateName = evt.name;
    modifyTemplate(bar, row);
    return;
  }

  let isUpdated = false;
  if (actn === 'datetime-change') {
    bar.start = evt.start;
    bar.end   = evt.end;
    isUpdated = true;
  }
  else
  if (actn === 'source-address-change') {
    bar.sourceAddress = evt.address;
  }
  else
  if (actn === 'target-address-change') {
    bar.targetAddress = evt.address;
  }
  else
  if (actn === 'source-location-change') {
    bar.sourceLocation = {
      lat: evt.lat,
      lng: evt.lng
    };
  }
  else
  if (actn === 'target-location-change') {
    bar.targetLocation = {
      lat: evt.lat,
      lng: evt.lng
    };
  }
  else
  if (actn === 'resize-left' || actn === 'resize-right') {
    const isLeft = actn.endsWith('left');
    isUpdated = resizeBar(bar, row, (isLeft ? 'left' : 'right'));
  }
  else
  if (actn === 'resize-both') {
    isUpdated   = resizeBar(bar, row, 'left');
    isUpdated ||= resizeBar(bar, row, 'right');
  }
  else
  if (actn === 'resize-to-bar-left' || actn === 'resize-to-bar-right') {
    const isLeft = actn.endsWith('left');
    isUpdated = resizeBarToNearest(bar, row, (isLeft ? 'left' : 'right'));
  }
  else
  if (actn === 'resize-to-bundle-left' || actn === 'resize-to-bundle-right') {
    const isLeft = actn.endsWith('left');
    isUpdated = resizeBarToBundleLimits(bar, (isLeft ? 'left' : 'right'));
  }
  else
  if (actn === 'resize-to-order-left' || actn === 'resize-to-order-right') {
    const isLeft = actn.endsWith('left');
    isUpdated = resizeBarToOrderLimits(bar, row, (isLeft ? 'left' : 'right'));
  }

  if (isUpdated) {
    upsertTask(bar);
  }
}

// updateTimelineRows

let _timelineModel;
let _insertLane;
let _timeline;

export function updateTimelineRows(timelineModel, timeline) {

  _timelineModel = timelineModel;
  _timeline = timeline;

  const getNewLane = (row, index, lastRoad) => {
    if (typeof index === 'undefined') {
      index = 0;
    }
    return {
      ...(lastRoad ? {
          category: row.category,
          title: row.category,
          road:  lastRoad+1,
          icon:  row.icon,
          color: row.color,
        } : row),
      id: 'timeline_row_' + (timelineModel.value.length + index + 1),// 'timeline_row_' + row.id,
      lane: (lastRoad ? index : row.lane) + 1,
      style: '',
      bars: []
    };
  }

  const getRoadRows = (roadId) => {
    const rows = [];
    _timelineModel.value.forEach(irow => {
      if (irow.roadId === roadId) {
        rows.push(irow);
      }
    });
    return rows;
  }

  const insertLane = (row, index) => {
    // console.log('onRowClickNewLane', row, index);
    if (!row?.rowId) {
      console.log('No rowId specified! Exiting...');
      return;
    }
    if (row.category === 'action') {
      console.log('Not allowed multiple action lanes! Exiting...');
      return;
    }
    if (showNextHiddenRow(row.rowId, row.prevRowId, row.category, timeline.value.start, timeline.value.end)) {
      return;
    }
    getDoc(row.rowId)
    .then(async lastRowDoc => {
      const uuids = await getUuids(1);
      const rowId = uuids[0];

      const docs = [];
      lastRowDoc.next = rowId;
      docs.push(lastRowDoc);
      docs.push({
        _id: rowId,
        cat: 'row',
        row_cat: row.category,
        road: row.roadId,
        next: row.nextRowId
      });
      row.nextRowId = rowId;
      prepareToShowRow(rowId, row.category);
      // console.log('insert lane saveOrDelete docs', docs);

      const results = await saveOrDelete(docs);
      // console.log('insert lane saveOrDelete results', results);
    })
    .catch(err => {
      console.log('insert lane failed', err);
    });
  }

  _insertLane = insertLane;

  const insertRoad = () => {
    // console.log('onRowClickNewRoad');

    const lastRow = timelineModel.value.length ? timelineModel.value[ timelineModel.value.length - 1 ] : null;
    getUuids(BAR_CATEGORIES.length + 1)
    .then(async uuids => {
      const roadId = uuids.splice(0, 1)[0];
      let lastRowDoc = null;
      if (lastRow?.rowId) {
        lastRowDoc = await getDoc(lastRow.rowId);
      }
      const docs = [];
      BAR_CATEGORIES.forEach((cat, index) => {
        const rowId = uuids[index];
        docs.push({
          _id: rowId,
          cat: 'row',
          row_cat: cat.category,
          road: roadId
        });
        if (index === 0) {
          if (lastRowDoc) {
            lastRowDoc.next = rowId;
          }
        }
        else {
          docs[index - 1].next = rowId;
        }
      });
      docs[docs.length - 1].next = null;
      if (lastRowDoc) {
        docs.unshift(lastRowDoc);
      }
      await saveOrDelete(docs);
    })
    .catch(err => {
      console.log('Insert road failed', err);
    });
  }

  const deleteLane = (row, index) => {
    // console.log('deleteLane');

    const start = dayjs(timeline.value.start, DATE_FORMAT);
    const end = dayjs(timeline.value.end, DATE_FORMAT);
    const bars = row.bars.filter(bar => !bar.completed && dayjs(bar.start, DATE_FORMAT).isBefore(end) && dayjs(bar.end, DATE_FORMAT).isAfter(start));
    if (bars.length) {
      prepareTasksForUpdate(bars, true)
      .then(docs => {
        saveOrDelete(docs)
        .then(async res => {
          console.log('deleteLane ok', bars);
          const counts = await rowCountByRoadAndRowCat(row.roadId, row.category);
          if (counts.get(row.category) && counts.get(row.category) > 1) {
            const countPerRow = await taskCountByRow([row.rowId]);
            if ((countPerRow.get(row.rowId) || 0) === 0) {
              const getIds = [];
              if (row.prevRowId) {
                getIds.push(row.prevRowId);
              }
              getIds.push(row.rowId);
              const docs = await getDocs(getIds);
              const updateDocs = [];
              const prevDoc = docs.get(row.prevRowId);
              if (prevDoc) {
                prevDoc.next = row.nextRowId;
                updateDocs.push(prevDoc);
              }
              const delDoc = docs.get(row.rowId);
              delDoc._deleted = true;
              updateDocs.push(delDoc);
              await saveOrDelete(updateDocs);
            }
            else {
              hideRow(row.rowId, row.category, timeline.value.start, timeline.value.end);
            }
          }
        })
        .catch(err => {
          console.log('deleteLane error', err, bars);
        });
      })
      .catch(err => {
        console.log('deleteLane error2', err, bars);
      });
    }
    else {
      rowCountByRoadAndRowCat(row.roadId, 'action', 'vehicle')
      .then(async counts => {
        if (counts) {
          let allSingle = true;
          counts.forEach(value => allSingle &= (value === 1));
          const currentMultiple = counts.get(row.category) && counts.get(row.category) > 1;

          if (allSingle || currentMultiple) {
            //try automatic delete of all road rows are of unique type and without tasks
            //if only current row matches conditions - delete only it
            const rows = allSingle ? getRoadRows(row.roadId) : [row];
            const rowIds = rows.map(row => row.rowId);
            const countPerRow = await taskCountByRow(rowIds);
            let allZero = true;
            rowIds.forEach(rowId => allZero &= (countPerRow.get(rowId) || 0) === 0);

            if (allZero) {
              const getIds = [...rowIds];
              let hasPrev = false;
              if (rows[0].prevRowId) {
                hasPrev = true;
                getIds.unshift(rows[0].prevRowId);
              }
              const docs = await getDocs(getIds);
              console.log('----autoclean getIds, docs', getIds, docs);
              const updateDocs = [];
              getIds.forEach((id, index) => {
                const doc = docs.get(id);
                if (index === 0 && hasPrev) {
                  doc.next = rows[rows.length - 1].nextRowId;
                }
                else {
                  doc._deleted = true;
                }
                updateDocs.push(doc);
              });
              await saveOrDelete(updateDocs);
            }
            else if (currentMultiple) {
              hideRow(row.rowId, row.category, timeline.value.start, timeline.value.end);
            }
          }
        }
      })
      .catch(err => {
        console.log('deleteLane - autoclean error', err);
      });
    }
    // const lanes = timelineModel.value.filter(irow => irow.road === row.road && irow.category === row.category);
    // if (lanes?.length > 1) {
    //   timelineModel.value.splice(index, 1);
    // }
  }

  return {
    insertLane,
    insertRoad,
    deleteLane
  }
}

// Task (timeline bars) processing
// rowContextMenu

const getMinStartBar = (bars) => bars.sort((a, b) => a.start < b.start ? -1 : (a.start == b.start ? 0 : 1))[0];
const getMaxEndBar   = (bars) => {
  const sbars = bars.sort((a, b) => a.end < b.end ? -1 : (a.end == b.end ? 0 : 1));
  return sbars[sbars.length - 1];
}

const getNextBarOnRow = (bar, row) => {
  const barStart = dayjs(bar.start, DATE_FORMAT);
  const barsAfter = [];
  let skip = false;
  row.bars.forEach(otherBar => {
    if (skip) {
      return;
    }
    const otherBarStart = dayjs(otherBar.start, DATE_FORMAT);
    // Bar start crosses other
    if (barStart.isAfter(dayjs(otherBar.start, DATE_FORMAT)) && barStart.isBefore(dayjs(otherBar.end, DATE_FORMAT))) {
      skip = true;
      return;
    }
    else
    // Bar starts before other
    if (barStart.isBefore(otherBarStart)) {
      barsAfter.push(otherBar);
    }
  });
  if (skip) {
    return false;
  }
  let earliestBar = barsAfter ? getMinStartBar(barsAfter) : null;
  return earliestBar || null;
}
const getNearestBar = (bar, row, dir) => {
  let skip = false;
  let nearestBar = null;

  row.bars.forEach(iBar => {
    if (skip) {
      return;
    }
    // Bar intersection (target bar.start crosses iterated-bar)
    if (bar.start > iBar.start && bar.start < iBar.end) {
      skip = true;
      console.log('getNearestBar - intersection between bars', bar, iBar);
      return;
    }
    // Bar starts before iterated-bar
    if (dir === 'right') {
      if (bar.start < iBar.start) {
        if (!nearestBar) {
          if (bar.start < iBar.start) {
            nearestBar = iBar;
          }
        }
        else
        if (iBar.start < nearestBar.start) {
          nearestBar = iBar;
        }
      }
    }
    // Bar starts after iterated-bar
    else {
      if (bar.start > iBar.start) {
        if (!nearestBar) {
          if (bar.start > iBar.start) {
            nearestBar = iBar;
          }
        }
        else
        if (iBar.start > nearestBar.start) {
          nearestBar = iBar;
        }
      }
    }
  });

  if (skip) {
    return false;
  }
  // console.log('nearestBar', nearestBar.start);
  return nearestBar;
}
const getBundleBars = (bar) => {
  let bundleBars = [];

  _timelineModel.value.forEach(row => {
    if (row.road !== null && row.road === bar.road) {
      row.bars.forEach(ibar => {
        if (ibar.courseId !== null && ibar.courseId === bar.courseId) {
          bundleBars.push(ibar);
        }
      });
    }
  });
  return bundleBars;
}
const getOrderBars = (bar, row) => {
  let orderBars = [];
  if (row.category === 'order') {
    orderBars.push(row.bars.filter(ibar => !bar.courseId || ibar.courseId === bar.courseId));
  }

  _timelineModel.value.forEach(irow => {
    if (irow.category === 'order') {
      //
      irow.bars.forEach(ibar => {
        if (ibar.road === bar.road && ibar.category === 'order' && (!bar.courseId || ibar.courseId === bar.courseId)) {
          orderBars.push(ibar);
        }
      });
    }
  });
  return orderBars;
}
const getVehicleBars = (bar, row) => {
  let vehicleBars = [];
  if (row.category === 'vehicle') {
    vehicleBars.push(row.bars);
  }

  _timelineModel.value.forEach(irow => {
    if (irow.category === 'vehicle') {
      //
      irow.bars.forEach(ibar => {
        if (ibar.road === bar.road && ibar.category === 'vehicle') {
          vehicleBars.push(ibar);
        }
      });
    }
  });
  return vehicleBars;
}

const getBundleByXTime = (xTime, road) => {
  let courseId = null;
  let skip = false;
  _timelineModel.value.forEach(row => {
    if (skip) {
      return;
    }
    if (row.road === road) {
      row.bars.forEach(bar => {
        if (bar.start <= xTime && bar.end > xTime) {
          courseId = bar.courseId;
          skip = true;
          return;
        }
      });
    }
  });
  return courseId;
}

const ganttBarConfigure = (bar, row) => {
  bar.ganttBarConfig = {
    id: ('timeline_bar_row_' + row.id + '_' + (row.bars ? row.bars.length + 1 : 0)),
    hasHandles: true
  };
}

const getBarEnd = (bar) => {
  let duration;
  if (bar.category === 'order' && bar.type === 'transport' && (bar.source_after || bar.source_until) && (bar.target_until || bar.target_after)) {
    duration = dayjs((bar.target_until || bar.target_after), DB_DATE_FORMAT).diff(dayjs((bar.source_after || bar.source_until), DB_DATE_FORMAT), 'minutes');
  }
  else {
    duration = TOOLBOX_ITEMS.find(elem => bar.type === elem.type || bar.category === elem.category)?.duration || 60;
  }
  const barEndDayjs = dayjs(bar.start, DATE_FORMAT).add(duration, 'minutes');
  return barEndDayjs;
}

const getLimitStr = (str1, str2) => {

  if (str1 > str2) {
    return {
      max: str1,
      min: str2
    }
  }
  else {
    return {
      max: str2,
      min: str1
    }
  }
}

const resizeBar = (bar, row, dir) => {
  return resizeBarToNearest(bar, row, dir) || resizeBarToBundleLimits(bar, dir) || resizeBarToOrderLimits(bar, row, dir);
}
const resizeBarToNearest = (bar, row, dir) => {
  const isLeft = (dir === 'left');

  let bundleLimit = getNearestBundleLimit(bar, dir);
  let neighbarLimit = getNearestBar(bar, row, dir);
  if (neighbarLimit) {
    neighbarLimit = neighbarLimit[isLeft ? 'end' : 'start'];
  }
  let limit = null;

  if (bundleLimit && neighbarLimit) {
    const getLimit = getLimitStr(bundleLimit, neighbarLimit);
    limit = isLeft ? getLimit.max : getLimit.min;
  }
  else
  if (bundleLimit) {
    limit = bundleLimit;
  }
  else
  if (neighbarLimit) {
    limit = neighbarLimit;
  }

  if (limit) {
    bar[isLeft ? 'start' : 'end'] = limit;
    return true;
  }
  return false;
}
const resizeBarToBundleLimits = (bar, dir) => {
  const isLeft = (dir === 'left');

  const bundle = getBundleLimits(bar);
  if (bundle) {
    bar[isLeft ? 'start' : 'end'] = isLeft ? bundle.start : bundle.end;
    return true;
  }
  return false;
}
const resizeBarToOrderLimits = (bar, row, dir) => {
  const isLeft = (dir === 'left');

  const orders = getOrderBars(bar, row);
  if (orders?.length) {
    bar[isLeft ? 'start' : 'end'] = isLeft ? getMinStartBar(orders)?.start : getMaxEndBar(orders)?.end;
    return true;
  }
  return false;
}

/*-------------------- Get limits of bundles or bars --------------------*/

const getNearestBundleLimit = (bar, dir) => {
  const isLeft = (dir === 'left');
  const { courseIdVsTasks } = useUtils();

  const bundle = courseIdVsTasks?.get(bar.courseId);
  if (!bundle) {
    return null;
  }

  let maxest = null;
  let minest = null;

  courseIdVsTasks?.forEach((val, courseId) => {// value, key, map

    if (val.tasks[0].road === bar.road && courseId !== bar.courseId) {
      if (isLeft) {
        if (val.max < bundle.min && (maxest === null || val.max > maxest)) {
          maxest = val.max;
        }
      }
      else {
        if (val.min > bundle.max && (minest === null || minest > val.min)) {
          minest = val.min;
        }
      }
    }
    // console.log('maxest : minest', maxest, minest);
  });
  let result = isLeft ? maxest : minest;
  return result ? dateFormatToDB(result) : null;
}

export const getBundleBox = (bar) => {// Reactive
  let start = null,
      end   = null,
      topRowId    = null,
      bottomRowId = null;

  _timelineModel.value.forEach(irow => {
    if (irow.road === bar.road) {
      if (!topRowId) {
        topRowId = irow.id;
      }
      else {
        bottomRowId = irow.id;
      }
      irow.bars.forEach(ibar => {
        if (bar.courseId !== null && ibar.courseId == bar.courseId) {
          if (!start || ibar.start < start) {
            start = ibar.start;
          }
          if (!end || ibar.end > end) {
            end = ibar.end;
          }
        }
      });
    }
  });

  return {
    start,
    end,
    topRowId,
    bottomRowId
  }
}

const dateFormatToDB = (str) => dayjs(str.replace(/\+/, '-'), DB_DATE_FORMAT).format(DATE_FORMAT);
const getBundleLimits = (bar) => {// Non-responsive
  if (!bar?.courseId) {
    return null;
  }
  const { courseIdVsTasks } = useUtils();
  const course = courseIdVsTasks?.get(bar.courseId);
  if (course) {
    return {
      start: dateFormatToDB(course.min),
      end:   dateFormatToDB(course.max)
    }
  }
  return false;
}

export const getBarsLimits = (bars) => {
  if (!bars?.length) {
    return null;
  }

  let startest = null;
  let endest = null;
  bars.forEach(ibar => {
    if (!startest || ibar.start < startest) {
      startest = ibar.start;
    }
    if (!endest || ibar.end > endest) {
      endest = ibar.end;
    }
  })
  return {
    start: startest,
    end:   endest
  }
}

/*-------------------- Select, multiselect, lock, bundle --------------------*/

export const selectedBar = ref(null);// used for Course RulerGuidelines only
export const selectedBars = ref([]);

export const selectBar = (bar, multi) => {

  if (!multi) {// Unselect all first
    deselectBars();
  }

  if (bar) {// Select
    selectedBars.value.push(bar);
    selectedBars.value.forEach(sbar => {
      sbar.selected = true;
      sbar.ganttBarConfig.bundle = selectedBars.value.length > 1;
    })
  }
  // console.log('selectedBars', selectedBars.value);
  selectedBar.value = bar || null;
}
export const deselectBars = () => {
  const { idVsBarModel } = useUtils();
  idVsBarModel.forEach(ibar => {
    ibar.selected = false;
    ibar.ganttBarConfig.bundle = null;
  });
  selectedBars.value = [];
  selectedBar.value = null;
}

export const resetDrag = () => {
  // console.log('resetDrag');
  _timelineModel.value.forEach(row => {
    row.bars.forEach(bar => {
      bar.ganttBarConfig.bundle = null;
      bar.ganttBarConfig.immobile = false;
      delete bar.ganttBarConfig.dragLimitLeft;
      delete bar.ganttBarConfig.dragLimitRight;
    })
  })
}

export const setBundleDragLimits = (bar) => {
  const { courseIdVsTasks, idVsBarModel } = useUtils();
  const course = courseIdVsTasks?.get(bar.courseId);
  if (course) {
    const courseBars = [];
    course.tasks.forEach(task => {
      const bar = idVsBarModel?.get(task.id);
      if (bar) {
        bar.ganttBarConfig.bundle = course.tasks.length > 1;
        courseBars.push(bar);
      }
    })
    console.log('setBundleDragLimits', bar, courseBars);
    setBarsDragLimits(courseBars);
  }
}
export const setSelectionDragLimits = () => {
  console.log('setBarsDragLimits', selectedBars.value);
  setBarsDragLimits(selectedBars.value);
}

const LOCK_MODE_NOT_FULL    = computed(() => store.value.prefs.lockMode === 'OFF' || store.value.prefs.lockMode === 'SHRINK');
const LOCK_MODE_NOT_PER_ROW = computed(() => store.value.prefs.lockMode !== 'PER_ROW');
const LOCK_MODE_COURSES     = computed(() => store.value.prefs.lockMode === 'BOUNDING_BOXES');
const LOCK_MODE_BLOCK       = computed(() => store.value.prefs.lockMode === 'BLOCK');

const setBarsDragLimits = (bars) => {
  console.log('setBarsDragLimits bars', bars);
  if (!bars?.length) {
    return;
  }
  if (LOCK_MODE_NOT_FULL.value) {
    return;
  }

  const rowIdVsLimits = new Map();// rowId, {innerLeft, innerRight, outerRight, outerLeft}

  // Set inner limits
  let limitInnerBoxLeft = null;
  let limitInnerBoxRight = null;
  bars.forEach(ibar => {
    let rowLimits = rowIdVsLimits.get(ibar.rowId);
    if (!rowLimits) {
      rowLimits = {
        innerLeft: ibar.start,
        innerRight: ibar.end
      }
      rowIdVsLimits.set(ibar.rowId, rowLimits);
    }
    else {
      if (ibar.start < rowLimits.innerLeft) {
        rowLimits.innerLeft = ibar.start;
      }
      if (ibar.end > rowLimits.innerRight) {
        rowLimits.innerRight = ibar.end;
      }
    }

    if (LOCK_MODE_COURSES.value) {
      if (!limitInnerBoxLeft || rowLimits.innerLeft < limitInnerBoxLeft) {
        limitInnerBoxLeft = rowLimits.innerLeft;
      }
      if (!limitInnerBoxRight || rowLimits.innerRight > limitInnerBoxRight) {
        limitInnerBoxRight = rowLimits.innerRight;
      }
    }
  })

  const { idVsRowModel } = useUtils();

  if (LOCK_MODE_COURSES.value) {
    const row = idVsRowModel.get(bars[0].rowId);
    const roadId = row?.roadId;
    if (roadId) {
      idVsRowModel.forEach((iRow, iRowId) => {
        if (iRow.roadId === roadId && !rowIdVsLimits.has(iRowId)) {
          rowIdVsLimits.set(iRowId, {
            innerLeft: limitInnerBoxLeft,
            innerRight: limitInnerBoxRight
          });
        }
      });
    }
  }

  // Set outer limits
  let limitOuterRight = null;
  let limitOuterLeft = null;
  rowIdVsLimits.forEach((limits, rowId) => {
    const bars = idVsRowModel.get(rowId)?.bars;
    if (!bars?.length) {
      return;
    }
    let endest = null;
    let startest = null;
    bars.forEach(ibar => {
      if ((!limits.innerLeft || ibar.end < limits.innerLeft) && (!endest || ibar.end > endest)) {
        endest = ibar.end;
      }
      if ((!limits.innerRight || ibar.start > limits.innerRight) && (!startest || ibar.start < startest)) {
        startest = ibar.start;
      }
    });

    limits.outerRight = endest;
    limits.outerLeft  = startest;

    if (LOCK_MODE_COURSES.value) {
      if (!limitOuterRight || endest > limitOuterRight) {
        limitOuterRight = endest;
        limits.outerRight = limitOuterRight;
      }
      if (!limitOuterLeft || startest < limitOuterLeft) {
        limitOuterLeft = startest;
        limits.outerLeft  = limitOuterLeft;
      }
    }

    if (LOCK_MODE_COURSES.value) {
      limits.innerLeft  = limitInnerBoxLeft;
      limits.innerRight = limitInnerBoxRight;
    }
  })

  // Lock mode block
  if (LOCK_MODE_BLOCK.value || LOCK_MODE_COURSES.value) {
    let minDistLeft = null;
    let minDistRight = null;
    let minDistLimitInnerLeft = null;
    let minDistLimitInnerRight = null;
    let minDistLimitOuterRight = null;
    let minDistLimitOuterLeft = null;
    rowIdVsLimits.forEach((limits, rowId) => {
      const difLeft = dayjs(limits.innerLeft, DATE_FORMAT).diff(dayjs(limits.outerRight, DATE_FORMAT), 'minute');
      if (!minDistLeft || difLeft < minDistLeft) {
        minDistLeft = difLeft;
        minDistLimitInnerLeft = limits.innerLeft;
        minDistLimitOuterRight = limits.outerRight;
      }
      const difRight = dayjs(limits.outerLeft, DATE_FORMAT).diff(dayjs(limits.innerRight, DATE_FORMAT), 'minute');
      if (!minDistRight || difRight < minDistRight) {
        minDistRight = difRight;
        minDistLimitInnerRight = limits.innerRight;
        minDistLimitOuterLeft = limits.outerLeft;
      }
    })
    rowIdVsLimits.forEach((limits, rowId) => {
      limits.innerLeft = minDistLimitInnerLeft;
      limits.innerRight = minDistLimitInnerRight;
      limits.outerRight = minDistLimitOuterRight;
      limits.outerLeft = minDistLimitOuterLeft;
    })
    // console.log('minDistRight', minDistRight);
  }

  // Set drag-limits
  const { mapTimeToPosition } = useTimePositionMapping(_timeline);
  bars.forEach(ibar => {
    let limits = rowIdVsLimits.get(ibar.rowId);

    const offsetLeft = dayjs(ibar.start, DATE_FORMAT).diff(dayjs(limits.innerLeft, DATE_FORMAT), 'minute');
    const offsetRight = dayjs(limits.innerRight, DATE_FORMAT).diff(dayjs(ibar.end, DATE_FORMAT), 'minute');
    ibar.ganttBarConfig.dragLimitLeft  = mapTimeToPosition(dayjs(limits.outerRight).add(offsetLeft, 'minute').utc().format(DATE_FORMAT));
    ibar.ganttBarConfig.dragLimitRight = mapTimeToPosition(dayjs(limits.outerLeft).add(-offsetRight, 'minute').utc().format(DATE_FORMAT));
  })
}

const deleteBar = (bar, row) => {
  if (bar.category === 'action' && bar.templateName) {
    let count = 0;
    row.bars.forEach(iBar => {
      if (iBar === bar || iBar.courseId !== bar.courseId) {
        return;
      }
      count++;
    });
    if (!count) {
      // remove template if no more actions in the course
      saveOrDeleteTemplate(bar.courseId, null);
    }
  }

  removeTask(bar);
}

const modifyTemplate = (bar, row) => {
  row.bars.forEach(iBar => {
    if (iBar === bar || iBar.courseId !== bar.courseId) {
      return;
    }
    iBar.templateName = bar.templateName;
  });

  saveOrDeleteTemplate(bar.courseId, bar.templateName);
}

export function updateTimelineBars(rowContextMenu, draggedItem) {

  const rowTarget = (evt) => {
    const rowElem = evt.target.closest('[data-row-cat]');
    const rowCat = rowElem.getAttribute('data-row-cat');

    return {
      row: rowElem,
      matched: rowCat === draggedItem?.value.category
    };
  }


  const onBarDblclick = (bar, e, datetime, row) => {
    e.stopPropagation();
    console.log("onBarDblclick-bar", bar, e, datetime, row);
    const elemClass = e.target?.className;

    if (elemClass.includes('g-gantt-bar-handle-')) {
      const isLeft = elemClass.endsWith('left');
      resizeBar(bar, row, (isLeft ? 'left' : 'right'));
    }
  }

  const onRowEnter = (evt) => {
    console.log('row-enter', rowTarget(evt).row, evt);
    rowTarget(evt).row?.classList.add( rowTarget(evt).matched ? 'row-matched' : 'row-not-matched' );
  }

  const onRowLeave = (evt) => {
    rowTarget(evt).row?.classList.remove('row-matched', 'row-not-matched');
  }

  const onRowDrop = (evt, datetime, row, rowindex) => {
    const bar = draggedItem.value;

    if (rowTarget(evt).matched) {
      bar.start = datetime;
      insertBar(bar, row, rowindex);
    }

    rowTarget(evt).row?.classList.remove('row-matched', 'row-not-matched');
    draggedItem.value = null;
    console.log('row-drop', bar, datetime, rowindex);
  }

  function getValue(obj, path) {
    path = path.split('.');
    for (let i = 0; i < path.length; i++) {
      obj = obj[path[i]];
    }
    return obj;
  }
  function sortLoadUnload(a, b, bar) {
    let dta, dtb;
    if (bar.type === 'load') {
      dta = dayjs(a.start, DATE_FORMAT).diff(dayjs(bar.start, DATE_FORMAT), 'minutes');
      dta *= dta;
      dtb = dayjs(b.start, DATE_FORMAT).diff(dayjs(bar.start, DATE_FORMAT), 'minutes');
      dtb *= dtb;
    }
    else {
      dta = dayjs(a.end, DATE_FORMAT).diff(dayjs(bar.end, DATE_FORMAT), 'minutes');
      dta *= dta;
      dtb = dayjs(b.end, DATE_FORMAT).diff(dayjs(bar.end, DATE_FORMAT), 'minutes');
      dtb *= dtb;
    }
    return dta - dtb;
  }
  function isIntersect(a, b) {
    const isIntersect = dayjs(a.end, DATE_FORMAT).isAfter(dayjs(b.start, DATE_FORMAT))
      && dayjs(a.start, DATE_FORMAT).isBefore(dayjs(b.end, DATE_FORMAT));
      console.log('isIntersect', isIntersect, a, b);
      return isIntersect;
  }
  function sortIntersect(a, b, bar) {
    let dta, dtb;
    if (isIntersect(bar, a)) {
        dta = 0;
    }
    else {
      let dta1 = dayjs(a.start, DATE_FORMAT).diff(dayjs(bar.start, DATE_FORMAT), 'minutes');
      dta1 *= dta1;
      let dta2 = dayjs(a.end, DATE_FORMAT).diff(dayjs(bar.end, DATE_FORMAT), 'minutes');
      dta2 *= dta2;
      dta = Math.max(dta1, dta2);
    }
    if (isIntersect(bar, b)) {
      dtb = 0;
    }
    else {
      let dtb1 = dayjs(b.start, DATE_FORMAT).diff(dayjs(bar.start, DATE_FORMAT), 'minutes');
      dtb1 *= dtb1;
      let dtb2 = dayjs(b.end, DATE_FORMAT).diff(dayjs(bar.end, DATE_FORMAT), 'minutes');
      dtb2 *= dtb2;
      dtb = Math.max(dtb1, dtb2);
    }
    if (isNaN(dta) || isNaN(dtb)) {
      return 24 * 60;
    }
    return dta - dtb;
  }
  const insertBar = (bar, row, index, isTemplateItem) => {
    // rowContextMenu
    let start = bar.start;
    if (!row) {
      row = rowContextMenu.value.row;
      index = rowContextMenu.value.index;
      start = rowContextMenu.value.xTime;// - bar.start = mouseX + snap step
    }

    const courseId = getBundleByXTime(start, row.road);
    const { courseIdVsTasks } = useUtils();
    const course = courseIdVsTasks?.get(courseId);
    console.log('start, row.road, courseId, courseIdVsTasks, course', start, row.road, courseId, courseIdVsTasks, course);

    let isInsertBar = true;
    if (course && course.tasks.find(task => task.cat === 'order')) {
      // OK
    }
    else
    if (bar.category === 'order') {
      // OK
    }
    else {
      console.log('Intersecting orders not found: Insert bar not allowed - abort operation');
      isInsertBar = false;
    }

    if (course && bar.category === 'action' && bar.type === 'template') {
      loadTemplate(bar._id, dateFormatToDB(course.min), dateFormatToDB(course.max))
      .then(async actions => {
        const bars = [];
        actions.forEach(action => {
          action.category = action.cat;
          action.oldCategory = action.cat;
          action.oldType = action.type;
          const iBar = insertBar(action, row, index, true);
          if (iBar) {
            bars.push(iBar);
          }
          else {
            console.log('Fail to insert action bar', action);
          }
        });
        const docs = await prepareTasksForUpdate(bars);
        const result = await saveOrDelete(docs);
      })
      .catch(error => console.log('loadTemplate faiiled', error));
      return;
    }

    const metaCategory = BAR_CATEGORIES.find(elem => elem.category === bar.oldCategory) || bar;
    const metaCategory2 = TOOLBOX_ITEMS.find(elem => elem.category === bar.oldCategory && elem.type === bar.oldType);
    const nbar = {
      ...bar,
      category  : metaCategory2?.category || metaCategory.category,
      icon      : metaCategory2?.icon     || metaCategory.icon,
      color     : metaCategory2?.color    || metaCategory.color,

      start     : start,
      end       : bar.end,

      name      : bar.name,
      cat       : bar.category,
      type      : bar.type,
      vehicleId: null,
      driverId : null,
      orderId  : null,
      courseId : null
    };
    if (metaCategory2?.keys?.length) {
      metaCategory2.keys.forEach(elem => {
        nbar[elem.key] = getValue(bar, elem.value);
      });
    }


    nbar.road = row.road;
    nbar.lane = row.lane;
    nbar.rowId = row.rowId;

    if (!isInsertBar) {
      setTaskState(nbar, 'NOT_ALLOWED');
    }

    // - bar.end = default || next bar.start
    if (!isTemplateItem) {
      nbar.end = getBarEnd(nbar).format(DATE_FORMAT);
    }

    if (courseId) {
      nbar.courseId = courseId;
    }
    const orders = getOrderBars(nbar, row);

    if (nbar.category === 'action' && (nbar.type === 'load' || nbar.type === 'unload' || nbar.type === 'empty')) {
      if (orders?.length) {
        const order = orders.sort((a, b) => {
          return sortLoadUnload(a, b, nbar);
        })[0];
        nbar.orderId = order.orderId;

        const { catItemMaps } = useUtils();
        const orderItem = catItemMaps.order.get(order.orderId);
        if (orderItem) {
          if (nbar.type === 'load') {
            nbar.sourceAddress = orderItem.source_address;
            const latLng = getLatLng(orderItem.source_location);
            if (latLng) {
              nbar.sourceLocation = {
                lat: latLng[0],
                lng: latLng[1]
              };
            }
          }
          else
          if (nbar.type === 'unload') {
            nbar.sourceAddress = orderItem.target_address;
            const latLng = getLatLng(orderItem.target_location);
            if (latLng) {
              nbar.sourceLocation = {
                lat: latLng[0],
                lng: latLng[1]
              };
            }
          }
        }
      }

      if (nbar.type === 'load' || nbar.type === 'unload') {
        const loadVehicles = getVehicleBars(nbar, row);
        if (loadVehicles?.length) {
          const vehicle = loadVehicles
          .filter(elem => elem.courseId === nbar.courseId)
          .sort((a, b) => {
            return sortLoadUnload(a, b, nbar);
          })
          .sort((a, b) => (a.lane - nbar.lane) * (a.lane - nbar.lane) - (b.lane - nbar.lane) * (b.lane - nbar.lane))
          [0];// TODO if more than one vehicle filtered user have to choose manualy
          nbar.vehicleId = vehicle?.vehicleId;
        }
      }
      else
      if (nbar.type === 'empty') {
        const vehicles = getVehicleBars(nbar, row);
        if (vehicles?.length) {
          const vehicle = vehicles
          .filter(elem => elem.courseId === nbar.courseId)
          .sort((a, b) => {
            return isIntersect(a, b, nbar);
          })
          .sort((a, b) => (a.lane - nbar.lane) * (a.lane - nbar.lane) - (b.lane - nbar.lane) * (b.lane - nbar.lane))
          [0];// TODO if more than one vehicle filtered user have to choose manualy
          nbar.vehicleId = vehicle?.vehicleId;
        }
      }
    }
    else if (nbar.category === 'action' && !isTemplateItem) {
      const leftBar = getNearestBar(nbar, row, 'left');
      if (leftBar && leftBar.courseId === nbar.courseId) {
        nbar.start = leftBar.end;
        nbar.end = getBarEnd(nbar).format(DATE_FORMAT);
        nbar.sourceAddress = leftBar.targetAddress || leftBar.sourceAddress || null;
        nbar.sourceLocation = {
          lat: leftBar.targetLocation?.lat || leftBar.sourceLocation?.lat || null,
          lng: leftBar.targetLocation?.lng || leftBar.sourceLocation?.lng || null
        };
      }
    }

    ganttBarConfigure(nbar, row);

    if (!isTemplateItem) {
      const nextBar = getNearestBar(nbar, row, 'right');
      const nextBarStart = nextBar?.start;
      if (typeof nextBar === 'boolean' && !nextBar) {
        // row = _insertLane(row, index);
        console.log('fail to insert bar - intersection: nextbar false', nbar, nextBar, row);
      }
      else
      if (nextBarStart && getBarEnd(nbar).isSameOrAfter( dayjs(nextBarStart, DATE_FORMAT) )) {
        nbar.end = nextBarStart;
      }
    }

    row.bars.push(nbar);
    rowContextMenu.value.isShow = false;

    let nbarModel = null;
    _timelineModel.value.forEach(irow => {
      if (nbarModel) {
        return;
      }
      nbarModel = irow.bars.find(ibar => ibar.ganttBarConfig.id === nbar.ganttBarConfig.id)
    });
    console.log('insertBar2', nbarModel, JSON.stringify(nbar));
    if (isInsertBar && !isTemplateItem) {
      upsertTask(nbarModel);
    }
    else {
      return nbar;
    }
  }

  return {
    insertBar,
    onRowDrop,
    onRowEnter,
    onRowLeave,
    onBarDblclick
  }
}
