export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/src/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/Callback',
    name: '/Callback',
    component: () => import('/src/src/pages/Callback.vue'),
    /* no children */
  },
  {
    path: '/Login',
    name: '/Login',
    component: () => import('/src/src/pages/Login.vue'),
    /* no children */
  },
  {
    path: '/Logout',
    name: '/Logout',
    component: () => import('/src/src/pages/Logout.vue'),
    /* no children */
  },
  {
    path: '/Renew',
    name: '/Renew',
    component: () => import('/src/src/pages/Renew.vue'),
    /* no children */
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

